@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black: #000000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  background-color: #f4f5fa;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: visible;
  max-width: 100%;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding: 10rem;
}

.swiper-pagination-bullet-active {
  background-color: #142644 !important;
}

.modal-spin-container.ant-spin-nested-loading .ant-spin-blur::after {
  border-radius: 15px !important;
}

.custom-dropdown.ant-select .ant-select-selector {
  height: 3rem !important;
  background-color: transparent !important;
  border: none !important;
  display: flex;
  align-items: center;
}

.custom-dropdown.ant-select .ant-select-selection-placeholder {
  color: white !important;
}

.custom-dropdown.ant-select .ant-select-selection-item {
  color: white !important;
}

.ant-upload-list-item-name {
  color: white !important;
}

.ant-upload-list .ant-upload-list-item .ant-upload-icon .anticon {
  color: white !important;
}

.ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .anticon {
  color: white !important;
}

.ant-input:hover {
  background-color: transparent !important;
}

.ant-input:focus-within {
  background-color: transparent !important;
}

.ant-input-password .ant-input {
  background-color: transparent !important;
  color: white !important;
}


.ant-input-password .ant-input {
  background-color: transparent !important;
  color: white !important;
}

.ant-input-password .ant-input:hover {
  background-color: transparent !important;
  color: white !important;
}

.ant-input-password:hover {
  background-color: transparent !important;
}

.ant-input-password:focus-within {
  background-color: transparent !important;
}

.ant-input-password .ant-input::placeholder {
  color: white !important;
}

.anticon.anticon-eye.ant-input-password-icon {
  color: white !important;
}

.anticon.anticon-eye.ant-input-password-icon:hover {
  color: white !important;
}

.anticon.anticon-eye-invisible.ant-input-password-icon {
  color: white !important;
}

.full-width-spin {
  width: 100%;
}

.full-width-spin .ant-spin-container {
  width: 100%;
}

.spin-30 {
  width: 30%;
}

.spin-30 .ant-spin-container {
  width: 100%;
}

.ant-otp {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ant-otp .ant-input {
  background-color: transparent !important;
  color: white !important;
  border-radius: 10px;
  height: 55px;
  width: 55px;
}

.ant-otp .ant-input:hover {
  background-color: transparent !important;
  border: 1px solid white !important;
  box-shadow: none !important;
}

.ant-otp .ant-input:focus {
  background-color: transparent !important;
  border: 1px solid white !important;
  box-shadow: none !important;
}

.ant-otp .ant-input:focus-within {
  background-color: transparent !important;
  border: 1px solid white !important;
  box-shadow: none !important;
}

.scroll::-webkit-scrollbar {
  width: 5px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding-right: 10px;
  overflow: hidden;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #142644;
}

.dashboard-input .ant-input {
  border-color: #6b7280 !important;
  color: black !important;
  caret-color: black !important;
  border-radius: 15px !important;
}

.dashboard-input .ant-input::placeholder {
  color: #6b7280 !important;
}

.black-border .ant-select-selector{
  border-radius: 15px !important;
}

.normal-height .ant-select-selector {
  min-height: 48px !important;
}

.wrapperClassName.rdw-editor-wrapper {
  background-color: white !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
}

@font-face {
  font-family: "Avenir_bold";
  src: url("../public/fonts/avenir-next-lt-pro/avenirnextltpro-bold.otf");
}

/* regular font */
@font-face {
  font-family: "Avenir_regular";
  src: url("../public/fonts/avenir-next-lt-pro/avenirnextltpro-regular.otf");
}

/* demi font */
@font-face {
  font-family: "Avenir_demi";
  src: url("../public/fonts/avenir-next-lt-pro/avenirnextltpro-demi.otf");
}

/* demicn font */
@font-face {
  font-family: "Avenir_demicn";
  src: url("../public/fonts/avenir-next-lt-pro/avenirnextltpro-demicn.otf");
}

/* italic font */
@font-face {
  font-family: "Avenir_italic";
  src: url("../public/fonts/avenir-next-lt-pro/avenirnextltpro-it.otf");
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -50px;
}

.swiper.swiper-initialized.swiper-horizontal {
  overflow: visible;
}
